<template>
  <v-container>
    <v-row class="justify-center align-self-start">
      <v-col class="col-md-8 col-sm-10">
        <v-card class="py-9 px-12">
          <v-card-title
            class="primary--text headline"
          >
            Data Refresh
          </v-card-title>

          <v-card-text>
            <div>
              <div id="estimated-time-of-arrival">
                <h4>
                  Data’s Estimated Time of Arrival
                </h4>

                <p>
                  Pacing data should be ready before 9 am Eastern time, while Discrepancy data follows
                  soon after around 9:30 am Eastern time.
                </p>
              </div>

              <div id="freshness">
                <h4>
                  Data Sources
                </h4>

                <p>
                  The data refresh datetime associated with the Pacing report represents the time the data was pulled from GAM.
                </p>

                <p>
                  The data refresh datetime associated with the Discrepancy report represents when the data was pulled from the
                  Discrepancy source. Up-to-date Discrepancy data should be through yesterday.
                </p>
              </div>

              <div id="time-zones">
                <h4>
                  Time Zones
                </h4>

                <p>
                  All data refresh datetimes are adjusted to the user’s local time zone.
                </p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('auth')

export default {
  name: 'ReleaseNotes',
  computed: {
    ...mapGetters(['isDevUser', 'isInternalUser']),
  },
}
</script>
